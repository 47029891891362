import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Video from "components/Video/Video.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/products/livesyncpresent/livesync_poster.jpg";
import image2 from "assets/img/products/livesyncpresent/livesync_concept.jpg";
import image3 from "assets/img/products/livesyncpresent/livesync_mosaic_lobby.png";
import image4 from "assets/img/products/livesyncpresent/livesync_player_content_drop.png";
import image5 from "assets/img/products/livesyncpresent/livesync_player_tags.png";
import image6 from "assets/img/products/livesyncpresent/livesync_mosaic_video.png";

import logo from "assets/img/products/livesyncpresent/livesync_icon.png";

class LiveSYNCPresentSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>LiveSYNC™ Presentation Solution</h2>
              <h4>Control VR video playback on a group of mobiles & VR headsets, observe their views, add annotations... and more</h4>
              <br/>
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <Video
                        videoSrcURL="https://www.youtube-nocookie.com/embed/2yfopMgltTc"
                        videoTitle="LiveSYNC Demo"
                        aspectRatio="54.00%"
                      />
                    </div>
                  )
                },
                {
                  tabName: "Photos",
                  tabIcon: "",
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>                  
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          With LiveSYNC, you use a tablet for controlling phones, tablets, and 
                          VR headsets wirelessly: show 360° photos, play 360° videos, and add 
                          annotations. Presenting is made easy: select a channel, connect your 
                          devices on this channel, and they will follow your control in sync. 
                          The content will be played from local files, so there is no quality 
                          drop nor bandwidth or latency issues.
                        </p>
                        <p>
                          The heart of the LiveSYNC presentation solution is the control app
                          for tablets. It is an intuitive yet feature-rich tool for all control
                          and observation needs, with a set of interactive tools. The control 
                          app automatically discovers nearby LiveSYNC compatible players 
                          (clients on the same channel), connects to them, and takes control. 
                          Since the players communicate back to the control app, observing 
                          client device views and status is possible from the tablet’s screen.
                        </p>
                        <p>
                          The audience can use personal devices such smartphones, tablets, or 
                          VR headsets for viewing the presentation. They will run the same app 
                          but in the audience mode (the control app runs in the director mode).
                        </p>
                        <p>
                          The control device can be connected to a big screen such as a TV or 
                          a projector. This allows sharing either the control device’s screen 
                          or the current view from a selected client device.
                        </p>
                        <p>
                          The devices communicate with each other either via local Bluetooth
                          connections (requires no infrastructure at all), or via our
                          GlobalSYNC servers that run in the cloud. In the latter case, each 
                          device requires Internet connectivity, but only minimal amount of 
                          data is transferred (control and feedback commands).
                        </p>
                        <p>
                          <b>
                            LiveSYNC is the perfect presentation tool for nursing homes, trade 
                            shows, and sales agents. Contact us to learn more or try it out.
                          </b>
                        </p>
                        <Button
                            round
                            color="success"
                            size="md"
                            href="contact-page/"
                            target="_self"
                            rel="noopener noreferrer"
                          >
                            Contact us
                        </Button>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={3} sm={3} md={2}>
                            <img
                              src={logo}
                              alt="Orion360 SDK"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={9} sm={9} md={4} className={classes.marginAuto}>
                            <h4>LiveSYNC™ Presentation Solution</h4>
                            <h4>Android</h4>
                            <h5><a href="https://play.google.com/store/apps/details?id=fi.finwe.livesync.player.android">Download from Google Play store</a></h5>
                          </GridItem>
                          <GridItem xs={3} sm={3} md={2}>
                            <img
                              src={logo}
                              alt="Orion360 SDK"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={9} sm={9} md={4} className={classes.marginAuto}>
                            <h4>LiveSYNC™ Presentation Solution</h4>
                            <h4>iOS</h4>
                            <h5><a href="https://apps.apple.com/us/app/livesync-presentation-solution/id1202200449?ls=1">Download from Apple AppStore</a></h5>
                          </GridItem>                          
                        </GridContainer>
                        <GridContainer>

                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(LiveSYNCPresentSection);
